import {createSlice} from "@reduxjs/toolkit";
import {AppState} from "./store";

export interface SiteState {
    site: any
    isLoading: boolean
}

const initialState: SiteState = {
    site: undefined,
    isLoading: false
};

const siteSlice = createSlice({
    name: 'site',
    initialState,
    reducers: {
        setSite(state, action) {
            state.site = action.payload
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload
        }
    }
});

export const selectSite = (state: AppState): any => state.site.site
export const selectIsLoading = (state: AppState): any => state.site.isLoading

export const {setSite, setIsLoading} = siteSlice.actions;


export default siteSlice.reducer