import {ApiError} from "../models/models";
import {toast} from "react-toastify";
import {toastOptions} from "./toast-options";

export function toastWarning(apiError: ApiError) {
    toast.warning(apiError.error[0], toastOptions)
}

const isMedusaJsErrorResponse = (e: any): boolean =>  {
    return e.response && e.response.data && e.response.data.type
}

const medusaErrorCodeToMessageMapping: {[key: string]: string} = {
    insufficient_inventory: 'Not enough stock available.',
}

export const rejectOrThrowError = (e: any, thunkAPI: any) => {
    if (isMedusaJsErrorResponse(e)) {
        const message = medusaErrorCodeToMessageMapping[e.response.data.code]
        if (message) {
            return thunkAPI.rejectWithValue({
                error: [message]
            })
        } else if (e.response.data.message) {
            return thunkAPI.rejectWithValue({
                error: [e.response.data.message]
            })
        }
    }
    throw e
}