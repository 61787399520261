import {createSlice} from "@reduxjs/toolkit";
import {AppState} from "./store";
import {CardReader, PosLocation} from "../models/models";
export const LOCAL_STORAGE_POS_KEY = "pos"

export interface PosState {
    location?: PosLocation;
    reader?: CardReader
}

const initialState: PosState = {
};

// @ts-ignore TODO
const posSlice = createSlice({
    name: 'pos',
    initialState,
    reducers: {
        initializePos(state) {
            const stored = localStorage.getItem(LOCAL_STORAGE_POS_KEY);
            if (stored) {
                const parsed = JSON.parse(stored)
                state.location = parsed.location
                state.reader = parsed.reader
            }
        },
        assignPosLocation(state, action) {
            localStorage.setItem(LOCAL_STORAGE_POS_KEY, JSON.stringify(action.payload))
            state.location = action.payload.location
            state.reader = action.payload.read
        },
        clearPosLocation(state) {
            localStorage.removeItem(LOCAL_STORAGE_POS_KEY)
            state.location = undefined
            state.reader = undefined
        }
    }
});

export const {assignPosLocation, clearPosLocation, initializePos} = posSlice.actions;

export const selectPosLocation = (state: AppState): PosLocation | undefined => state.pos?.location
export const selectPosCardReader = (state: AppState): CardReader | undefined => state.pos?.reader


export default posSlice.reducer
