import {ToastOptions, ToastPosition} from "react-toastify";

const position: ToastPosition = 'top-center'
export const toastOptions: ToastOptions = {
    position,
    autoClose: 500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    closeButton: false,
}